<template>
  <div class="div">
    <heand :tagnum="tagnum" />
    <div class="box">
       <!-- src="https://chuangke.aliyun.com/city/ty/portal" -->
<!-- http://alicity.welian.com/city/taiyuan-wanchuang -->
       <!-- http://gs.sxjx.wang/chuangke/ -->
      <iframe
       
        src="http://www.expocentralchina.org.cn/"
        frameborder="0"
        style="overflow: visible"
        height="100%"
        width="100%"
        id="menuFrame"
        :style="height"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tagnum: 6,
      height: "",
    };
  },
  mounted() {
    this.reinitIframe();
  },
  methods: {
    reinitIframe() {
      var h =
        document.documentElement.clientHeight || document.body.clientHeight;
      console.log(h);
      var iframe = document.getElementById("menuFrame");
      var bHeight = iframe.contentWindow.document.body.scrollHeight;
      var dHeight = iframe.contentWindow.document.documentElement.scrollHeight;
      var height = Math.min(bHeight, dHeight);
      this.height = "height:" + (h - 110) + "px";
      console.log(iframe.height);
      console.log(bHeight);
      console.log(dHeight);
    },
  },
};
</script>
<style lang="less" scoped>
.div {
  min-height: 100%;
  overflow: hidden;
  .box {
    width: 100%;
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
      html,
      body {
        height: 100%;
      }
    }
  }
}
</style>